// src/pages/PrefView.tsx
import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { collection, query, where, getDocs, onSnapshot, deleteDoc, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import pLogo from '../assets/p-logo.svg';
import { generateOwnerToken } from '../utils/PrefLink';
import { useAuth } from '../contexts/AuthContext';
import bg from '../assets/bg.png';
//import { updateMetaTags } from '../utils/metaUpdater';
import { trackEvent } from '../utils/analytics';

interface PrefLink {
    id: string;
    publicId: string;
    ownerToken: string;
    title: string;
    description?: string;
    createdAt: Date;
    expiresAt: Date;
    isLocked: boolean;
    unlockPrice: number;
    recommendationCount: number;
    viewCount: number;
    owner: string;
    unlockedBy: string[];
}


interface Recommendation {
    id: string;
    text: string;
    link?: string | null;
    metadata?: {
        title?: string;
        description?: string;
        image?: string;
        siteName?: string;
        favicon?: string;
    } | null;
    userId: string;
    createdAt: Date | { toDate(): Date } | any;
    PrefLinkId: string;
}

// Add this function outside of the component
const getMessageText = (title: string, viewUrl: string) => {
  const messageTemplates = [
    `Help me out! What's your pref for "${title}"? 🙏\n\n${viewUrl}`,
    `I know you've got good taste... What's your pref for "${title}"? 👀\n\n${viewUrl}`,
    `Quick! Need your pref for "${title}" 🤔\n\n${viewUrl}`,
    `Don't leave me hanging... What's your pref for "${title}"? 🎯\n\n${viewUrl}`,
    `Your taste >>> my taste. Share your pref for "${title}"? 🫶\n\n${viewUrl}`,
    `Bestie emergency! Need your pref for "${title}" asap ⚡️\n\n${viewUrl}`,
    `You're literally the first person I thought of for "${title}" 🎯\n\n${viewUrl}`,
    `If you don't help me find "${title}" I'll be lost forever 🥺\n\n${viewUrl}`
  ];
  
  return messageTemplates[Math.floor(Math.random() * messageTemplates.length)];
};

export function PrefView() {
  const [PrefLink, setPrefLink] = useState<PrefLink | null>(null);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [timeRemaining, setTimeRemaining] = useState<string>('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [anonymousId, setAnonymousId] = useState<string>('');
  const [isSaved, setIsSaved] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  //const hasSetMetaTags = useRef(false);

  const { ownerToken, publicId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const isOwnerView = location.pathname.startsWith('/pref/');
  const queryId = isOwnerView ? ownerToken : publicId;

//////////////////////////////////////////////////////////////
  // SHITTY FUCKING OPENGRAPH SHIT THAT DOESN'T FUCKING WORK FUCK
  ////////////////////////////////////////////////////////////
  // useEffect(() => {
  //   if (PrefLink?.title && !hasSetMetaTags.current) {
  //     // Only update meta tags once when PrefLink loads
  //     console.log('Setting meta tags for:', PrefLink.title);
  //     updateMetaTags(PrefLink.title);
  //     hasSetMetaTags.current = true;
  //   }
  // }, [PrefLink?.title]);
//////////////////////////////////////////////////////////////
  //  FUCK FUCK FUCK FUCK FUCK FUCK FUCK FUCK FUCK FUCK FUCK  //
  ////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!queryId) return;
  
    const fetchPrefLink = async () => {
      try {
        const queryField = isOwnerView ? 'ownerToken' : 'publicId';
        const q = query(
          collection(db, 'prefs'),
          where(queryField, '==', queryId)
        );
  
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0];
          const data = docData.data();
          
          // Helper function to handle different date formats
          const getDate = (dateField: any) => {
            if (!dateField) return new Date();
            if (dateField.toDate) return dateField.toDate();
            if (typeof dateField === 'string') return new Date(dateField);
            if (dateField instanceof Date) return dateField;
            return new Date();
          };

          setPrefLink({
            id: docData.id,
            title: data.title || '',
            description: data.description || '',
            createdAt: getDate(data.createdAt),
            expiresAt: getDate(data.expiresAt),
            isLocked: data.isLocked || false,
            unlockPrice: data.unlockPrice || 0,
            recommendationCount: data.recommendationCount || 0,
            viewCount: data.viewCount || 0,
            owner: data.owner || '',
            unlockedBy: data.unlockedBy || [],
            publicId: data.publicId || '',
            ownerToken: data.ownerToken || ''
          });
  
          // Set up real-time listener for recommendations
          const recsRef = collection(db, 'prefs', docData.id, 'recommendations');
          const unsubscribe = onSnapshot(recsRef, (snapshot) => {
            const recs = snapshot.docs.map(doc => {
              const data = doc.data();
              return {
                id: doc.id,
                text: data.text || '',
                userId: data.userId || '',
                createdAt: getDate(data.createdAt),
                PrefLinkId: data.PrefLinkId || '',
                link: data.link || null,
                metadata: data.metadata || null
              };
            });
            setRecommendations(recs);
          });
          return unsubscribe;
        }
      } catch (error) {
        console.error('Error fetching PrefLink:', error);
      }
    };
  
    fetchPrefLink();
  }, [queryId, isOwnerView]);
  
  useEffect(() => {
    if (!PrefLink) return;

    // Check if user is owner
    if (isOwnerView) {
      return;
    }

    // Check if list has expired
    const now = new Date();
    const isExpired = PrefLink.expiresAt.getTime() <= now.getTime();
    
    // Check if current user has unlocked the list
    const hasUserUnlocked = PrefLink.unlockedBy?.includes(anonymousId);
    
    // List is unlocked if:
    // 1. It's expired, OR
    // 2. It's not locked, OR
    // 3. Current user is in the unlockedBy array
    const isUnlocked = isExpired || !PrefLink.isLocked || hasUserUnlocked;

    // Use isUnlocked directly where needed in the UI
    console.log('Unlock state updated:', {
      isOwnerView,
      isExpired,
      isLocked: PrefLink.isLocked,
      hasUserUnlocked,
      anonymousId,
      unlockedBy: PrefLink.unlockedBy,
      finalUnlockState: isUnlocked
    });
  }, [PrefLink, isOwnerView, anonymousId]);

  useEffect(() => {
    // Get existing ID or create new one
    let id = localStorage.getItem('anonymousId');
    if (!id) {
      id = generateOwnerToken(); // or any UUID generation method
      localStorage.setItem('anonymousId', id);
    }
    setAnonymousId(id);
  }, []);

  useEffect(() => {
    if (!isOwnerView || !ownerToken) return;
    
    const savedLists = JSON.parse(localStorage.getItem('savedLists') || '{}');
    setIsSaved(!!savedLists[ownerToken]);
  }, [isOwnerView, ownerToken]);

  useEffect(() => {
    if (user && PrefLink && ownerToken && !isSaved) {
      handleSaveList();
    }
  }, [user, PrefLink, ownerToken]);

  const getUserRec = () => {
    if (!anonymousId) return null;
    return recommendations.find(rec => rec.userId === anonymousId);
  };

  const handleCopyLink = async () => {
    try {
      if (!PrefLink) return;
      
      const viewUrl = `${window.location.origin}/p/${PrefLink.publicId}`;
      await navigator.clipboard.writeText(viewUrl);
      showTemporaryToast('Link copied to clipboard');
      trackEvent('pref_shared', {
        prefId: PrefLink.id,
        method: 'copy'
      });
    } catch (error) {
      console.error('Error copying link:', error);
      showTemporaryToast('Failed to copy link');
    }
  };

  const handleAddRec = () => {
    if (!publicId || isExpired) return;
    navigate(`/submit/${PrefLink?.publicId}`);
  };

  const calculateTimeRemaining = (expiresAt: Date): string => {
    const now = new Date();
    const diff = expiresAt.getTime() - now.getTime();
    
    if (diff <= 0) return '0:00';
    
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);
    
    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
  };

  useEffect(() => {
    if (!PrefLink?.expiresAt) return;

    const updateCountdown = () => {
      const now = new Date();
      const expiry = PrefLink.expiresAt;
      const diff = expiry.getTime() - now.getTime();

      if (diff <= 0) {
        setTimeRemaining('0:00');
        setIsExpired(true);
        return;
      }

      setTimeRemaining(calculateTimeRemaining(PrefLink.expiresAt));
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [PrefLink?.expiresAt]);

  const handleDelete = async (recId: string) => {
    if (!PrefLink || !isOwnerView) return;
    
    if (!window.confirm('Are you sure you want to delete this recommendation?')) {
      return;
    }

    try {
      const recRef = doc(db, 'prefs', PrefLink.id, 'recommendations', recId);
      await deleteDoc(recRef);
    } catch (error) {
      console.error('Error deleting recommendation:', error);
    }
  };

  const handleSaveList = async () => {
    if (!PrefLink || !ownerToken) return;

    if (!user) {
      // If user isn't logged in, redirect to sign up with return URL
      const returnUrl = encodeURIComponent(window.location.pathname);
      navigate(`/signup?returnUrl=${returnUrl}&action=save-list&listToken=${ownerToken}`);
      return;
    }

    // If user is logged in, save the list
    try {
      // Save to Firestore using consistent collection name
      const userListsRef = doc(db, 'users', user.uid, 'savedPrefs', ownerToken);
      
      // Check if the document already exists
      const docSnap = await getDoc(userListsRef);
      const isNewSave = !docSnap.exists();

      await setDoc(userListsRef, {
        title: PrefLink.title,
        savedAt: serverTimestamp(),
        ownerToken: ownerToken,
        publicId: PrefLink.publicId
      });

      setIsSaved(true);
      
      // Only show toast if this is a new save
      if (isNewSave) {
        showTemporaryToast('List saved! Find it on your home page');
      }
    } catch (error) {
      console.error('Error saving list:', error);
      showTemporaryToast('Failed to save list');
    }
  };

  const renderEmptyState = () => {
    if (isOwnerView) {
      return (
        <div className="text-center py-2">
          {!user && (
            <div className="bg-preferred-pink/10 rounded-lg p-4 mb-8 text-left">
              <p className="text-off-white font-bold mb-2">
                🔥 PRO TIP
              </p>
              <p className="text-off-white/80 text-sm mb-2">
                Save this list and we'll notify you when recommendations come in! Just add your phone number to get started.
              </p>
              <p className="text-off-white/60 text-xs mb-4">
                You'll also be able to see all your saved lists in one place.
              </p>
              <button 
                onClick={handleSaveList}
                className="w-full p-3 bg-preferred-pink/100 rounded-[15px] text-off-white font-bold text-sm"
              >
                SAVE THIS LIST & GET NOTIFICATIONS
              </button>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="text-center py-12">
        <h2 className="text-[32px] text-off-white font-black mb-8 leading-none">
          BE THE FIRST TO DROP SOME KNOWLEDGE!
        </h2>
        <button 
          onClick={handleAddRec}
          className="w-full p-4 bg-preferred-purple/100 rounded-[20px] text-off-white font-bold"
        >
          ADD YOUR REC TO THIS LIST 💡
        </button>
      </div>
    );
  };

  const renderRecommendations = () => {
    if (recommendations.length === 0) {
        return renderEmptyState();
    }

    return (
        <div className="space-y-4">
            {recommendations.map((rec: Recommendation) => (
                <div key={rec.id} className="relative flex gap-3">
                    {/* Recommendation content */}
                    <div className="flex-1">
                        <div className="bg-off-white/10 rounded-lg overflow-hidden">
                            {rec.metadata?.image && (
                                <a 
                                    href={rec.link || '#'} 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="block"
                                >
                                    <img 
                                        src={rec.metadata.image} 
                                        alt={rec.metadata.title || 'Recommendation'} 
                                        className="w-full h-48 object-cover"
                                    />
                                </a>
                            )}
                            <div className="p-4">
                                {rec.metadata?.title && (
                                    <h3 className="text-off-white font-black text-xl mb-2">
                                        {rec.metadata.title}
                                    </h3>
                                )}
                                <p className="text-off-white/80 text-sm mb-3">
                                    {rec.text}
                                </p>
                                {rec.metadata?.siteName && (
                                    <p className="text-[#8B2F86] text-xs font-bold">
                                        {rec.metadata.siteName}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Delete button for owner */}
                    {isOwnerView && (
                        <button 
                            onClick={() => handleDelete(rec.id)}
                            className="absolute top-2 right-2 p-2 text-off-white/40 
                                     hover:text-preferred-pink transition-colors"
                            aria-label="Delete recommendation"
                        >
                            ✕
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
  };

  const renderUserRecommendation = () => {
    const userRec = getUserRec();
    if (!userRec) return null;

    return (
      <div className="mb-6">
        <div className="text-preferred-pink/60 font-black text-sm tracking-wide mb-2">
          YOU RECOMMENDED ✨
        </div>
        <div className="bg-off-white/10 rounded-lg overflow-hidden">
          {userRec.metadata?.image && (
            <a 
              href={userRec.link || '#'} 
              target="_blank" 
              rel="noopener noreferrer" 
              className="block transition-transform transform hover:scale-105"
            >
              <img 
                src={userRec.metadata.image} 
                alt={userRec.metadata.title || 'Recommendation'} 
                className="w-full h-48 object-cover"
              />
            </a>
          )}
          <div className="p-4">
            {userRec.metadata?.title && (
              <h3 className="text-off-white font-bold mb-2">{userRec.metadata.title}</h3>
            )}
            <p className="text-off-white/80 mb-4">"{userRec.text}"</p>
            {/* {userRec.metadata?.siteName && (
              <p className="text-off-white/60 text-sm">{userRec.metadata.siteName}</p>
            )} */}
          </div>
        </div>
        
        
      </div>
    );
  };

  const renderContent = () => {
    if (isExpired) {
      return (
        <div>
          {renderRecommendations()}
        </div>
      );
    }

    // If it's not owner view and there are recommendations, show locked state
    if (!isOwnerView && recommendations.length > 0) {
      return (
        <div>
          {renderUserRecommendation()}

          <div className="text-center py-8">
            <h2 className="text-[32px] text-off-white font-black mb-4">
              LIST LOCKED 🔒
            </h2>
            <p className="text-off-white/60 mb-6">
              This list will be revealed in {timeRemaining}
            </p>
            
            {!getUserRec() && !isExpired && (
              <button 
                onClick={() => navigate(`/submit/${PrefLink?.publicId}`)}
                className="w-full p-4 bg-preferred-pink rounded-[20px] text-off-white font-bold"
              >
                ADD YOUR REC TO THIS LIST
              </button>
            )}
          </div>
        </div>
      );
    }

    // For owner view or when there are no recommendations yet
    return (
      <div>
        {renderRecommendations()}
        
        {/* Only show Add button if there are recommendations and user hasn't submitted */}
        {!isOwnerView && recommendations.length > 0 && !getUserRec() && (
          <div className="mt-6">
            <button 
              onClick={handleAddRec}
              className="w-full p-4 bg-preferred-pink rounded-[20px] text-off-white font-bold"
            >
              ADD YOUR REC TO THIS LIST
            </button>
          </div>
        )}
      </div>
    );
  };

  const showTemporaryToast = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000); // Hide after 3 seconds
  };

  // Update the iMessage button onClick handler
  const handleMessageShare = () => {
    if (!PrefLink) return;
    const viewUrl = `${window.location.origin}/p/${PrefLink.publicId}`;
    const messageText = getMessageText(PrefLink.title, viewUrl);
    window.location.href = `sms:&body=${encodeURIComponent(messageText)}`;
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setIsSaved(false); // Reset saved state
      // The !user check in the render will automatically show the "pro tip" alert
      // since useAuth hook will update the user state to null
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const renderHeader = () => {
    return (
      <div className="max-w-md mx-auto px-0 py-4">
        <div className="mb-8">
          <img 
            src={pLogo} 
            alt="Pref" 
            className="w-32 brightness-200 mx-auto mb-4" 
          />
          
          {/* User controls */}
          {user && (
            <div className="flex gap-2 justify-center">
              <button 
                onClick={() => navigate('/home')}
                className="px-4 py-2 bg-[#8B2F86] rounded-[15px] text-off-white text-sm font-bold"
              >
                MY SAVED LISTS
              </button>
              <button 
                onClick={handleLogout}
                className="px-4 py-2 bg-off-white/10 rounded-[15px] text-off-white text-sm"
              >
                LOG OUT
              </button>
            </div>
          )}
        </div>

        {/* Moving the card here */}
        {isOwnerView && recommendations.length === 0 && (
          <div className="bg-off-white/5 rounded-lg p-4 my-8">
            <h1 className="text-off-white/100 font-black text-[39px] leading-none m-4 mb-8">
              POV: YOUR FRIENDS ARE ABOUT TO MAKE YOUR LIFE SO EASY
            </h1>
             
            <div className="space-y-3">
              <button 
                onClick={handleMessageShare}
                className="w-full p-4 bg-[#34C759] rounded-[20px] text-off-white font-bold"
              >
                SHARE WITH YOUR GROUP CHAT
              </button>
              <button 
                onClick={handleCopyLink}
                className="w-full text-off-white/60 text-sm py-2 hover:text-off-white transition-colors"
              >
                or copy link to share
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  // Add this helper function to handle the "Start your own" action
  const handleStartOwn = () => {
    navigate('/new');
  };

  useEffect(() => {
    if (!PrefLink) return;

    const updateTimeRemaining = () => {
      const now = new Date();
      const expiry = PrefLink.expiresAt;
      const diff = expiry.getTime() - now.getTime();

      if (diff <= 0) {
        setTimeRemaining('');
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

      if (hours > 0) {
        setTimeRemaining(`${hours}H ${minutes}M`);
      } else {
        setTimeRemaining(`${minutes}M`);
      }
    };

    // Update immediately
    updateTimeRemaining();

    // Then update every minute
    const interval = setInterval(updateTimeRemaining, 60000);

    return () => clearInterval(interval);
  }, [PrefLink]);

  if (!PrefLink) {
    return (
      <div 
        className="min-h-screen bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="max-w-md mx-auto px-6 py-12">
          <img 
            src={pLogo} 
            alt="Pref" 
            className="w-32 mx-auto mb-16 brightness-200" 
          />
          <p className="text-off-white">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen p-6 bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-md mx-auto flex flex-col min-h-[calc(100vh-48px)]">
        {renderHeader()}
        
        <div className="flex flex-col gap-2 mb-6">
          <div className="flex justify-between items-center">
            <h3 className="text-off-white/60 font-black">
              {recommendations.length} RECS FOR
            </h3>
            <div className="text-off-white/60 font-mono">
              {isExpired ? (
                "LIST IS NOW CLOSED"
              ) : (
                `LIST REVEALS IN ${timeRemaining}`
              )}
            </div>
          </div>
          
          <h1 className="text-off-white text-[48px] uppercase leading-[1.0] font-black">
            {PrefLink?.title}
          </h1>
        </div>

        {PrefLink.description && (
          <div className="bg-off-white/5 rounded-lg p-6 mb-8">
            <h3 className="text-off-white/30 font-mono text-sm tracking-wide mb-2">
              {isOwnerView ? 'YOU SAID...' : 'YOUR FRIEND SAID...'}
            </h3>
            <blockquote className="text-off-white/80 text-sm">
              "{PrefLink.description}"
            </blockquote>
          </div>
        )}

        <div className="flex-grow mb-6">
          {renderContent()}
        </div>
        {/* Bottom CTA */}
        <div className="sticky bottom-6">
          {isOwnerView ? (
            <div className="space-y-4">
              {recommendations.length > 0 && (
                <>
                  <div className="flex gap-2">
                    <button 
                      onClick={handleMessageShare}
                      className="flex-1 p-4 bg-[#34C759] rounded-[20px] text-off-white font-bold"
                    >
                      SHARE WITH YOUR GROUP CHAT
                    </button>
                    <button 
                      onClick={handleCopyLink}
                      className="flex-1 p-4 bg-preferred-purple/80 rounded-[20px] text-off-white font-bold"
                    >
                      COPY LINK
                    </button>
                  </div>
                  
                  {!user && (
                    <button 
                      onClick={handleSaveList}
                      className="w-full p-4 bg-preferred-pink rounded-[20px] text-off-white font-bold"
                    >
                      SAVE THIS LIST & GET NOTIFICATIONS
                    </button>
                  )}
                </>
              )}
            </div>
          ) : (
            <div>
              <div className='flex gap-2'>
                {getUserRec() && (
                  <button 
                    onClick={() => navigate(`/submit/${PrefLink?.publicId}?update=${getUserRec()?.id}`)}
                    className="w-full p-4 mt-4 bg-preferred-purple/100 rounded-[20px] mb-2 text-off-white font-bold sticky bottom"
                  >
                    ADD ANOTHER REC
                  </button>
                )}
              </div>
              <div className="flex gap-2">
                <button 
                  onClick={handleCopyLink}
                  className="flex-1 p-3 bg-preferred-pink rounded-[15px] text-off-white font-bold text-sm"
                >
                  SHARE WITH A FRIEND
                </button>
                <button 
                  onClick={handleStartOwn}
                  className="flex-1 p-3 bg-preferred-pink rounded-[15px] text-off-white font-bold text-sm"
                >
                  START MY OWN LIST
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Toast message */}
        {showToast && (
          <div className="fixed top-8 inset-x-0 mx-auto
                        bg-[#8B2F86] text-off-white px-6 py-3 rounded-full
                        shadow-lg font-bold text-center w-[90%] max-w-[400px]
                        z-50 animate-fade-in-up">
            {toastMessage}
          </div>
        )}
      </div>
    </div>
  );
}