import { useState, useEffect } from 'react';

interface LocationData {
  city: string;
  region: string;
  country: string;
  timezone: string;
  latitude: number;
  longitude: number;
}

const SPOOF_LOCATIONS = {
  NYC: {
    city: 'New York',
    region: 'NY',
    country: 'US',
    timezone: 'America/New_York',
    latitude: 40.7128,
    longitude: -74.0060
  },
  LA: {
    city: 'Los Angeles',
    region: 'CA',
    country: 'US',
    timezone: 'America/Los_Angeles',
    latitude: 34.0522,
    longitude: -118.2437
  }
} as const;

// Add this type for region mapping
type RegionMapping = {
  [key: string]: string;
};

// Add this region mapping object
const REGION_CODE_MAPPING: RegionMapping = {
  'New York': 'NY',
  'California': 'CA',
  'Illinois': 'IL',
  'Texas': 'TX',
  'Pennsylvania': 'PA',
  'Arizona': 'AZ',
  'Nevada': 'NV',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Massachusetts': 'MA',
  'Washington': 'WA',
  'Colorado': 'CO',
  'Tennessee': 'TN',
  'Oregon': 'OR',
  'North Carolina': 'NC',
  'Indiana': 'IN',
  'Ohio': 'OH',
  'Kentucky': 'KY',
  'Oklahoma': 'OK',
  'Wisconsin': 'WI',
  'New Mexico': 'NM',
  'Hawaii': 'HI',
  'Missouri': 'MO',
  'Minnesota': 'MN',
  'Louisiana': 'LA',
  'Michigan': 'MI',
  'Utah': 'UT',
  'South Carolina': 'SC',
};

// Add special city-region mappings
const CITY_REGION_MAPPING: RegionMapping = {
  'Dallas': 'TX-Dallas',
  'San Antonio': 'TX-SA',
  'San Diego': 'CA-SD',
  'San Francisco': 'CA-SF',
  'Tampa': 'FL-TPA',
  'Austin': 'TX-AUS',
  'St. Louis': 'MO-STL',
  'Tucson': 'AZ-TUS',
  'Orlando': 'FL-ORL',
  'Fort Worth': 'TX-FTW',
  'Reno': 'NV-RNO',
  'Pittsburgh': 'PA-PT',
  'Fort Lauderdale': 'FL-FTL',
};

export function useLocation(spoofLocation?: keyof typeof SPOOF_LOCATIONS) {
  const [location, setLocation] = useState<LocationData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocation = async () => {
      // If spoofing is enabled, return the spoofed location immediately
      if (spoofLocation) {
        setLocation(SPOOF_LOCATIONS[spoofLocation]);
        setLoading(false);
        return;
      }

      try {
        // Using ipdata.com API with your key
        const response = await fetch('https://api.ipdata.co/?api-key=1f83e154e2b4a993ba3f1e0ad89122ab244bb3f21b396983a3e4061a');
        const locationData = await response.json();
        
        // Get the region code from either city-specific or state mapping
        const regionCode = CITY_REGION_MAPPING[locationData.city] || 
                         REGION_CODE_MAPPING[locationData.region] ||
                         locationData.region_code;

        setLocation({
          city: locationData.city,
          region: regionCode, // Use our mapped region code
          country: locationData.country_name,  // Note: ipdata uses country_name instead of country
          timezone: locationData.time_zone.name,  // Note: time_zone.name instead of timezone
          latitude: locationData.latitude,
          longitude: locationData.longitude
        });
      } catch (error) {
        console.error('Error fetching location:', error);
        // Fallback to browser's geolocation API
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              try {
                // Reverse geocoding using OpenCage API (supports CORS)
                const response = await fetch(
                  `https://api.opencagedata.com/geocode/v1/json?q=${position.coords.latitude}+${position.coords.longitude}&key=YOUR_API_KEY`
                );
                const data = await response.json();
                const result = data.results[0];

                setLocation({
                  city: result.components.city || result.components.town,
                  region: result.components.state,
                  country: result.components.country,
                  timezone: result.annotations.timezone.name,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                });
              } catch (geocodeError) {
                console.error('Error geocoding:', geocodeError);
                setLocation({
                  city: 'New York',
                  region: 'NY',
                  country: 'US',
                  timezone: 'America/New_York',
                  latitude: 40.7128,
                  longitude: -74.0060
                });
              }
            },
            () => {
              // Geolocation failed, use fallback
              setLocation({
                city: 'New York',
                region: 'NY',
                country: 'US',
                timezone: 'America/New_York',
                latitude: 40.7128,
                longitude: -74.0060
              });
            }
          );
        } else {
          // Geolocation not supported, use fallback
          setLocation({
            city: 'New York',
            region: 'NY',
            country: 'US',
            timezone: 'America/New_York',
            latitude: 40.7128,
            longitude: -74.0060
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
  }, [spoofLocation]);

  return { location, loading };
}