import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { PhoneAuthProvider, signInWithCredential } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import pLogo from '../assets/p-logo.svg';

import bg from '../assets/bg.png';
import { trackEvent } from '../utils/analytics';

export function SignUpPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { signInWithPhone } = useAuth();
  
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [step, setStep] = useState<'phone' | 'code'>('phone');
  const [error, setError] = useState('');
  
  const returnUrl = searchParams.get('returnUrl') || '/home';
  const action = searchParams.get('action');
  const listToken = searchParams.get('listToken');

  const formatPhoneNumber = (phoneNumber: string) => {
    // Remove all non-numeric characters
    const cleaned = phoneNumber.replace(/\D/g, '');
    
    // Add US country code if not present
    const withCountry = cleaned.startsWith('1') ? cleaned : `1${cleaned}`;
    
    // Add + prefix
    return `+${withCountry}`;
  };

  const handleSendCode = async () => {
    try {
      const formattedPhone = formatPhoneNumber(phone);
      const verificationId = await signInWithPhone(formattedPhone);
      setVerificationId(verificationId);
      setStep('code');
      trackEvent('phone_verification_started', {
        success: !error
      });
    } catch (error) {
      console.error('Error sending code:', error);
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Only allow digits, spaces, parentheses, and hyphens
    if (/^[\d\s()-]*$/.test(value)) {
      setPhone(value);
    }
  };

  const handleVerifyCode = async () => {
    try {
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const userCredential = await signInWithCredential(auth, credential);
      
      // If this was triggered by saving a list, save it now
      if (action === 'save-list' && listToken) {
        const listDoc = await getDoc(doc(db, 'prefs', listToken));
        if (listDoc.exists()) {
          const listData = listDoc.data();
          await setDoc(doc(db, 'users', userCredential.user.uid, 'savedPrefs', listToken), {
            title: listData.title,
            savedAt: serverTimestamp(),
            ownerToken: listToken,
            publicId: listData.publicId
          });
        }
      }

      navigate(returnUrl);
      trackEvent('phone_verification_completed', {
        success: !error
      });
    } catch (error: any) {
      console.error('Error verifying code:', error);
      
      // Show user-friendly error message
      let errorMessage = 'Failed to verify code. Please try again.';
      
      if (error.code === 'permission-denied') {
        errorMessage = 'Unable to save list. Please try again later.';
      } else if (error.code === 'invalid-verification-code') {
        errorMessage = 'Invalid verification code. Please check and try again.';
      }
      
      // Add error state and display to user
      setError(errorMessage);
    }
  };

  return (
    <div 
      className="min-h-screen bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-md mx-auto px-6 py-12">
        <img 
          src={pLogo} 
          alt="Pref" 
          className="w-32 mx-auto mb-16 brightness-200" 
        />
        <h1 className="text-[40px] text-off-white font-black mb-4">
          {step === 'phone' ? 'Add your phone' : 'Enter the code'}
        </h1>
        
        {error && (
          <div className="bg-red-500/10 text-red-500 p-4 rounded-lg mb-4">
            {error}
          </div>
        )}
        
        <p className="text-off-white/80 mb-8">
          {step === 'phone' 
            ? "We'll text you when people add recommendations to your lists!" 
            : "We just sent you a 6-digit code. Enter it below!"}
        </p>

        {step === 'phone' ? (
          <div className="space-y-4">
            <input
              type="tel"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="(555) 555-5555"
              className="w-full p-4 bg-off-white/10 rounded-[15px] text-off-white"
            />
            <button
              onClick={handleSendCode}
              disabled={phone.replace(/\D/g, '').length < 10}
              className="w-full p-4 bg-[#8B2F86] rounded-[20px] text-off-white font-bold disabled:opacity-50"
            >
              SEND CODE
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="123456"
              className="w-full p-4 bg-off-white/10 rounded-[15px] text-off-white"
            />
            <button
              onClick={handleVerifyCode}
              disabled={verificationCode.length !== 6}
              className="w-full p-4 bg-[#8B2F86] rounded-[20px] text-off-white font-bold disabled:opacity-50"
            >
              VERIFY & CONTINUE
            </button>
          </div>
        )}
      </div>
    </div>
  );
}