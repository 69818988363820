import { useState, useEffect } from 'react';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import pLogo from '../assets/p-logo.svg';
import bg from '../assets/bg.png';

interface SavedList {
  title: string;
  ownerToken: string;
  publicId: string;
  savedAt: Date;
}

export function HomePage() {
  const [savedLists, setSavedLists] = useState<SavedList[]>([]);
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    const fetchSavedLists = async () => {
      try {
        const savedPrefsRef = collection(db, `users/${user.uid}/savedPrefs`);
        const savedPrefsSnap = await getDocs(savedPrefsRef);
        
        const lists = savedPrefsSnap.docs.map(doc => ({
          ...doc.data(),
          savedAt: doc.data().savedAt?.toDate() || new Date(),
        })) as SavedList[];
        
        setSavedLists(lists.sort((a, b) => b.savedAt.getTime() - a.savedAt.getTime()));
      } catch (error) {
        console.error('Error fetching saved lists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSavedLists();
  }, [user, navigate]);

  const showTemporaryToast = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const handleDeleteList = async (ownerToken: string, event: React.MouseEvent) => {
    event.stopPropagation();
    
    if (!user) return;
    
    if (!window.confirm('Are you sure you want to delete this list?')) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'users', user.uid, 'savedLists', ownerToken));
      setSavedLists(prev => prev.filter(list => list.ownerToken !== ownerToken));
      showTemporaryToast('List deleted');
    } catch (error) {
      console.error('Error deleting list:', error);
      showTemporaryToast('Failed to delete list');
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      showTemporaryToast('Failed to log out');
    }
  };

  if (loading) {
    return (
      <div 
        className="min-h-screen bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="max-w-md mx-auto px-6 py-12">
          <img 
            src={pLogo} 
            alt="Pref" 
            className="w-32 mx-auto mb-16 brightness-200" 
          />
          <p className="text-off-white">Loading your lists...</p>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="min-h-screen bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-md mx-auto px-6 py-12">
        {/* <img 
          src={pLogo} 
          alt="Pref" 
          className="w-32 mx-auto mb-16 brightness-200" 
        /> */}
        <div className="flex justify-between items-center mb-8">
          <img src={pLogo} alt="Preferred" className="w-20 brightness-200" />
          <div className="flex gap-2">
            <button 
              onClick={() => navigate('/new')}
              className="px-4 py-2 bg-[#8B2F86] rounded-[15px] text-off-white text-sm font-bold"
            >
              NEW LIST
            </button>
            <button 
              onClick={handleLogout}
              className="px-4 py-2 bg-off-white/10 rounded-[15px] text-off-white text-sm"
            >
              LOG OUT
            </button>
          </div>
        </div>

        <h1 className="text-[40px] text-off-white font-black mb-8">
          YOUR LISTS
        </h1>

        {savedLists.length === 0 ? (
          <div className="text-center py-12">
            <p className="text-off-white/60 mb-6">
              You haven't saved any lists yet!
            </p>
            <button
              onClick={() => navigate('/new')}
              className="w-full p-4 bg-[#8B2F86] rounded-[20px] text-off-white font-bold"
            >
              CREATE YOUR FIRST LIST
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            {savedLists.map((list) => (
              <div
                key={list.ownerToken}
                className="group relative bg-off-white/10 rounded-lg hover:bg-off-white/20 transition-colors"
              >
                <button
                  onClick={() => navigate(`/pref/${list.ownerToken}`)}
                  className="w-full p-4 text-left"
                >
                  <h3 className="text-off-white font-bold mb-2">{list.title}</h3>
                  <p className="text-off-white/60 text-sm">
                    Saved {list.savedAt.toLocaleDateString()}
                  </p>
                </button>
                <button
                  onClick={(e) => handleDeleteList(list.ownerToken, e)}
                  className="absolute right-2 top-2 p-2 text-off-white/40 
                           hover:text-preferred-pink transition-colors
                           opacity-0 group-hover:opacity-100"
                  aria-label="Delete list"
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      {showToast && (
        <div className="fixed bottom-6 left-1/2 transform -translate-x-1/2 
                      bg-[#8B2F86] text-off-white px-6 py-3 rounded-full
                      shadow-lg font-bold text-center max-w-md mx-auto w-[90%]
                      animate-fade-in-up">
          {toastMessage}
        </div>
      )}
    </div>
  );
}