import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { sanitizeMetadata } from '../utils/urlMetadata';
import pLogo from '../assets/p-logo.svg';
import bg from '../assets/bg.png';
import { Search, MapPin, ShoppingBag, Film, Star, Link as LinkIcon } from 'lucide-react';
import { magicSearch } from '../utils/magicSearch';
import { useLocation } from '../hooks/useLocation';
import { trackEvent } from '../utils/analytics';

// Add interface for metadata
interface URLMetadata {
  title?: string;
  description?: string;
  image?: string;
  siteName?: string;
  favicon?: string;
  type?: string;
  author?: string;
  price?: string;
}

// Add the fetchUrlMetadata function at the top
const fetchUrlMetadata = async (url: string): Promise<URLMetadata | null> => {
  try {
    // Check if it's a Google Shopping URL
    if (url.includes('google.com/shopping')) {
      // Return null to use the existing metadata
      return null;
    }

    // For non-Google Shopping URLs, try to fetch metadata
    const response = await fetch(`https://api.linkpreview.net/?key=7cea8f8e11ae0080d2f218fc277f81cb&q=${encodeURIComponent(url)}`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch metadata');
    }

    const data = await response.json();
    return {
      title: data.title,
      description: data.description,
      image: data.image,
      siteName: new URL(url).hostname.replace('www.', ''),
      favicon: `https://www.google.com/s2/favicons?domain=${url}`,
      type: 'article' // Default type
    };
  } catch (error) {
    console.error('Error fetching URL metadata:', error);
    return null;
  }
};

// Add URL validation and formatting helperve
const formatUrl = (url: string): string => {
  if (!url) return url;
  if (!url.match(/^https?:\/\//i)) {
    return `https://${url}`;
  }
  return url;
};

interface RecommendationData {
  text: string;
  link: string | null;
  metadata: URLMetadata | null;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  PrefLinkId: string;
}

export function SubmitRecommendation() {
  const { publicId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  
  // State declarations
  const [PrefLink, setPrefLink] = useState<{ id: string; title: string; description?: string; } | null>(null);
  const [link, setLink] = useState('');
  const [metadata, setMetadata] = useState<URLMetadata | null>(null);
  const [description, setDescription] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [type, setType] = useState<'place' | 'thing' | 'media' | 'general'>('general');
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [anonymousId, setAnonymousId] = useState<string>('');

  // Add a ref to track the previous search
  const previousSearch = useRef({ query: '', type: '', location: null });
  const searchTimeoutRef = useRef<number | null>(null);

  // Update debounced search function
  const performSearch = useCallback(async (query: string, searchType: string, userLocation: any) => {
    // Skip if query is empty
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    // Skip if this is the same search as before
    const currentSearch = JSON.stringify({ query, type: searchType, location: userLocation });
    const prevSearch = JSON.stringify({ 
      query: previousSearch.current.query, 
      type: previousSearch.current.type, 
      location: previousSearch.current.location 
    });
    
    if (currentSearch === prevSearch) {
      return;
    }

    // Update previous search
    previousSearch.current = { query, type: searchType, location: userLocation };

    setSearching(true);
    try {
      const searchTypeForAPI = searchType === 'thing' ? 'product' : searchType;
      const results = await magicSearch(
        query, 
        userLocation, 
        searchTypeForAPI as any,
        PrefLink?.title
      );
      console.log('Search results:', results);
      setSearchResults(results || []);
      trackEvent('search_performed', {
        query,
        type: searchType,
        resultCount: results.length
      });
    } catch (error) {
      console.error('Search error:', error);
      setSearchResults([]);
    } finally {
      setSearching(false);
    }
  }, [PrefLink?.title]);

  // Handle search query changes
  const handleSearchQueryChange = useCallback((newQuery: string) => {
    setSearchQuery(newQuery);
    
    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      window.clearTimeout(searchTimeoutRef.current);
    }

    // If query is empty, clear results immediately
    if (!newQuery.trim()) {
      setSearchResults([]);
      return;
    }

    // Set new timeout
    searchTimeoutRef.current = window.setTimeout(() => {
      performSearch(newQuery, type, location);
    }, 1000);
  }, [type, location, performSearch]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        window.clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  // Effect for fetching PrefLink
  useEffect(() => {
    if (!publicId) {
      setError('No PrefLink ID provided');
      setLoading(false);
      return;
    }

    const fetchPrefLink = async () => {
      try {
        setLoading(true);
        const q = query(
          collection(db, 'prefs'),
          where('publicId', '==', publicId)
        );
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const docData = querySnapshot.docs[0];
          const data = docData.data();
          setPrefLink({ 
            id: docData.id,
            title: data.title || '',
            description: data.description || ''
          });
        } else {
          setError('PrefLink not found');
        }
      } catch (error) {
        console.error('Error fetching PrefLink:', error);
        setError('Failed to load PrefLink');
      } finally {
        setLoading(false);
      }
    };

    fetchPrefLink();
  }, [publicId]); // Only depend on publicId

  // Effect for metadata fetching
  useEffect(() => {
    let isActive = true;

    const fetchMetadata = async () => {
      if (!link || !link.startsWith('http')) {
        setMetadata(null);
        return;
      }

      // Skip fetching if we already have metadata with matching URL
      if (metadata?.siteName === new URL(link).hostname.replace('www.', '')) {
        return;
      }

      try {
        const newMetadata = await fetchUrlMetadata(link);
        if (isActive && newMetadata) {
          setMetadata(sanitizeMetadata(newMetadata));
        }
      } catch (error) {
        if (isActive) {
          setMetadata(null);
        }
      }
    };

    fetchMetadata();

    return () => {
      isActive = false;
    };
  }, [link]); // Only run when link changes

  // Effect for anonymous ID
  useEffect(() => {
    const id = localStorage.getItem('anonymousId');
    if (id) {
      setAnonymousId(id);
    } else {
      navigate(`/p/${publicId}`);
    }
  }, [publicId, navigate]); // Only these two dependencies

  // Add this helper function
  const getSearchPlaceholder = (type: string) => {
    switch (type) {
      case 'place': return 'Search for restaurants, bars, cafes...';
      case 'thing': return 'Search for products...';
      case 'media': return 'Search for movies, shows, books...';
      default: return 'Search for anything...';
    }
  };

  // Update the link state handler to format URLs
  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(formatUrl(e.target.value));
  };

  // Update form validation
  const isFormValid = () => {
    // Allow submission if either description or link is provided
    return description.trim() !== '' || (link && link.trim() !== '');
  };

  // Update handleSubmit
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!PrefLink?.id || submitting || !isFormValid()) {
      return;
    }

    setSubmitting(true);
    setError('');

    try {
      const recommendationData: RecommendationData = {
        text: description.trim(),
        link: link.trim() || null,
        metadata: metadata,
        createdAt: new Date(),
        updatedAt: new Date(),
        userId: anonymousId,
        PrefLinkId: PrefLink.id,
      };
      
      const recommendationsRef = collection(db, 'prefs', PrefLink.id, 'recommendations');
      await addDoc(recommendationsRef, recommendationData);
      
      // Force navigation back to the PrefView page
      window.location.href = `/p/${publicId}`;
      trackEvent('recommendation_added', {
        prefId: PrefLink.id,
        hasLink: !!link,
        type
      });
    } catch (error) {
      console.error('Submission error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to submit recommendation';
      setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  // Move these functions INSIDE the component
  const getDescriptionTemplate = (result: any) => {
    switch (result.type) {
      case 'place':
        return `${result.title} is a great spot! ${result.subtitle || ''}`;
      case 'thing':
        return `I recommend the ${result.title}. ${result.price ? `It's available for ${result.price}.` : ''}`;
      case 'media':
        return `I recommend checking out ${result.title}${result.subtitle ? ` - ${result.subtitle}` : ''}.`;
      default:
        return '';
    }
  };

  const handleResultSelect = async (result: any) => {
    console.log('Selected result:', result); // Debug log for incoming data
    setShowUrlInput(true);
    
    // For products, ensure we have a properly formatted URL
    const url = result.product_link || result.link || result.url; // Check all possible URL fields
    console.log('URL found:', url); // Debug log for URL

    // Always create metadata, even without URL
    const metadata = {
      title: result.title,
      description: result.subtitle || '',
      image: result.image || result.thumbnail,
      siteName: result.merchant || result.source || 'Google Shopping',
      favicon: url ? `https://www.google.com/s2/favicons?domain=${url}` : undefined,
      type: 'product',
      price: result.price,
      rating: result.rating?.toString(),
      reviews: result.reviews?.toString(),
      author: result.merchant || result.source
    };
    
    if (url) {
      setLink(url);
    }
    
    console.log('Pre-sanitized metadata:', metadata);
    const sanitizedMetadata = sanitizeMetadata(metadata);
    console.log('Post-sanitized metadata:', sanitizedMetadata);
    setMetadata(sanitizedMetadata);
    
    const descriptionTemplate = getDescriptionTemplate(result);
    setDescription(descriptionTemplate);
    setSearchQuery('');
    setSearchResults([]);
    trackEvent('result_selected', {
      query: searchQuery,
      type,
      resultTitle: result.title
    });
  };

  // Add this helper function to clear form fields
  const clearFormFields = () => {
    setLink('');
    setMetadata(null);
    setDescription('');
    setSearchQuery('');
    setSearchResults([]);
    setShowUrlInput(false);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-preferred-purple p-6 flex flex-col items-center">
        <div className="w-full max-w-md px-6 py-12 flex flex-col items-center">
          <img src={pLogo} alt="Preferred" className="w-12 mb-12 brightness-200" />
          <p className="text-off-white">Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-preferred-purple p-6 flex flex-col items-center">
        <div className="w-full max-w-md px-6 py-12 flex flex-col items-center">
          <img src={pLogo} alt="Preferred" className="w-12 mb-12 brightness-200" />
          <div className="text-red-400 bg-preferred-pink/10 p-4 rounded-lg text-center">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="min-h-screen bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-md mx-auto px-6 py-12">
        <img 
          src={pLogo} 
          alt="Pref" 
          className="w-32 mx-auto mb-16 brightness-200" 
        />
        
        {PrefLink && (
          <div className="text-center mb-6">
          
            <p className="text-[32px] uppercase text-off-white font-black mb-8 leading-none">
              {PrefLink.title}
            </p>
            {PrefLink.description && (
              <p className="mt-2 text-off-white/60 italic">
                "{PrefLink.description}"
              </p>
            )}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
          {error && (
            <div className="text-red-400 bg-preferred-pink-400/10 p-4 rounded-lg text-center">
              {error}
            </div>
          )}
          
          {/* Type selector with clearing functionality */}
          <div className="flex gap-2 mb-6">
            <button
              type="button"
              onClick={() => {
                if (type !== 'place') {
                  setType('place');
                  clearFormFields();
                }
              }}
              className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors
                         ${type === 'place' 
                           ? 'bg-preferred-pink text-off-white' 
                           : 'bg-off-white/5 text-off-white/40'}`}
            >
              <MapPin size={16} /> Places
            </button>
            <button
              type="button"
              onClick={() => {
                if (type !== 'thing') {
                  setType('thing');
                  clearFormFields();
                }
              }}
              className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors
                         ${type === 'thing'
                           ? 'bg-preferred-pink text-off-white'
                           : 'bg-off-white/5 text-off-white/40'}`}
            >
              <ShoppingBag size={16} /> Products
            </button>
            <button
              type="button"
              onClick={() => {
                if (type !== 'media') {
                  setType('media');
                  clearFormFields();
                }
              }}
              className={`flex-1 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors
                         ${type === 'media'
                           ? 'bg-preferred-pink text-off-white'
                           : 'bg-off-white/5 text-off-white/40'}`}
            >
              <Film size={16} /> Media
            </button>
          </div>

          {/* Search input and loading state */}
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearchQueryChange(e.target.value)}
              placeholder={getSearchPlaceholder(type)}
              className="w-full p-4 pl-12 bg-off-white/10 rounded-lg text-off-white 
                       placeholder:text-off-white/40 focus:outline-none"
            />
            <Search className="absolute left-4 top-4 text-off-white/40" size={20} />
            {searching && (
              <div className="absolute right-4 top-4 text-off-white/60 text-sm">
                LOADING...
              </div>
            )}
          </div>

          {/* Search results with simplified display */}
          {searchResults.length > 0 && (
            <div className="bg-off-white/5 rounded-lg overflow-hidden max-h-96 overflow-y-auto">
              {searchResults.map((result) => (
                <button
                  key={result.id}
                  type="button"
                  onClick={() => handleResultSelect(result)}
                  className="w-full p-4 flex items-center gap-4 hover:bg-off-white/10 transition-colors"
                >
                  {result.image ? (
                    <img 
                      src={result.image} 
                      alt="" 
                      className="w-12 h-12 object-cover rounded"
                    />
                  ) : (
                    <div className="w-12 h-12 bg-off-white/10 rounded flex items-center justify-center">
                      {result.type === 'place' && <MapPin />}
                      {result.type === 'thing' && <ShoppingBag />}
                      {result.type === 'media' && <Film />}
                    </div>
                  )}
                  
                  <div className="flex-1 text-left">
                    <h4 className="text-off-white font-bold">{result.title}</h4>
                    {result.type === 'thing' && result.rating && (
                      <div className="flex items-center gap-1 text-off-white/60 text-sm">
                        <Star size={12} className="fill-current" />
                        <span>{result.rating}</span>
                        {result.merchant && (
                          <span className="ml-1">· {result.merchant}</span>
                        )}
                      </div>
                    )}
                    {result.type !== 'thing' && result.subtitle && (
                      <p className="text-off-white/60 text-sm">{result.subtitle}</p>
                    )}
                  </div>

                  {result.type === 'thing' && result.price && (
                    <div className="text-off-white font-bold">
                      {result.price}
                    </div>
                  )}
                  {result.type !== 'thing' && result.rating && (
                    <div className="flex items-center gap-2">
                      <div className="flex items-center text-off-white/80">
                        <Star size={16} className="fill-current" />
                        <span className="ml-1">{result.rating}</span>
                      </div>
                    </div>
                  )}
                </button>
              ))}
            </div>
          )}

          {/* URL input toggle button */}
          <button
            type="button"
            onClick={() => setShowUrlInput(!showUrlInput)}
            className="w-full p-3 flex items-center justify-center gap-2 text-off-white/60 
                     hover:text-off-white transition-colors"
          >
            <LinkIcon size={16} />
            {showUrlInput ? 'Hide URL input' : 'Or add a URL instead'}
          </button>

          {/* URL input (only shown when toggled) */}
          {showUrlInput && (
            <div className="space-y-4">
              <input
                type="url"
                value={link}
                onChange={handleLinkChange}
                placeholder="Paste a link..."
                className="w-full p-4 bg-off-white/10 rounded-lg text-off-white 
                         placeholder:text-off-white/40 focus:outline-none"
              />
              {metadata && (
                <div className="mt-4 bg-off-white/5 rounded-lg overflow-hidden">
                  {metadata.image && (
                    <div className="relative h-48">
                      <img 
                        src={metadata.image} 
                        alt={metadata.title}
                        className="w-full h-full object-cover"
                      />
                      {metadata.type === 'product' && metadata.price && (
                        <div className="absolute top-4 right-4 bg-[#8B2F86] px-3 py-1 rounded-full text-off-white font-bold">
                          {metadata.price}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="p-4 text-left">
                    <div className="flex items-center gap-2 mb-2">
                      {metadata.favicon && (
                        <img 
                          src={metadata.favicon} 
                          alt="" 
                          className="w-4 h-4"
                        />
                      )}
                      <span className="text-[#8B2F86] text-sm">
                        {metadata.siteName}
                      </span>
                      {metadata.type === 'article' && metadata.author && (
                        <span className="text-off-white/60 text-sm">
                          by {metadata.author}
                        </span>
                      )}
                    </div>
                    <h3 className="text-off-white font-bold mb-2">{metadata.title}</h3>
                    {metadata.description && (
                      <p className="text-off-white/60 text-sm line-clamp-3">
                        {metadata.description}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Description textarea (shown after selection or URL input) */}
          {(metadata || searchResults.length === 0) && (
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Add any extra details..."
              className="w-full p-4 bg-off-white/10 rounded-lg text-off-white 
                       placeholder:text-off-white/40 focus:outline-none
                       min-h-[100px] resize-y"
            />
          )}

          {/* Submit button */}
          <button
            type="submit"
            disabled={submitting || !isFormValid()}
            className="w-full p-4 bg-preferred-purple rounded-lg text-off-white font-bold
                     disabled:opacity-50 hover:bg-opacity-90 transition-all"
          >
            {submitting ? 'SUBMITTING...' : 'SUBMIT REC'}
          </button>
        </form>
      </div>
    </div>
  );
}