// src/utils/magicSearch.ts
import axios from 'axios';

// API Constants
const SPOTIFY_CLIENT_ID = import.meta.env.VITE_SPOTIFY_CLIENT_ID;
const SPOTIFY_CLIENT_SECRET = import.meta.env.VITE_SPOTIFY_CLIENT_SECRET;
const FOURSQUARE_API_KEY = import.meta.env.VITE_FOURSQUARE_API_KEY;
const FOURSQUARE_API_URL = 'https://api.foursquare.com/v3/places/search';
const SERP_API_KEY = import.meta.env.VITE_SERP_API_KEY;
const SERP_API_URL = 'https://serpapi.com/search.json';

interface MagicSearchResult {
  id: string;
  type: 'place' | 'thing' | 'media';
  title: string;
  subtitle?: string;
  image?: string;
  url?: string;
  price?: string;
  rating?: number;
  source: 'foursquare' | 'spotify';
  location?: string;
  reviews?: number;
  description?: string;
  categories?: string[];
}

interface LocationData {
  city: string;
  region: string;
  country: string;
  coords?: {
    latitude: number;
    longitude: number;
  };
}

interface FoursquareLocation {
  address?: string;
  city?: string;
  country?: string;
  formatted_address?: string;
  locality?: string;
  region?: string;
}

interface FoursquareResult {
  fsq_id: string;
  name: string;
  location: FoursquareLocation;
  rating?: number;
  photos?: Array<{
    prefix: string;
    suffix: string;
  }>;
  description?: string;
  categories?: Array<{
    name: string;
  }>;
  distance?: number;
}

interface FoursquareResponse {
  results: FoursquareResult[];
}

// Spotify token management
let spotifyToken: string = '';
let spotifyTokenExpiry: number = 0;

async function getSpotifyToken(): Promise<string> {
  if (spotifyToken && Date.now() < spotifyTokenExpiry) {
    return spotifyToken;
  }

  if (!SPOTIFY_CLIENT_ID || !SPOTIFY_CLIENT_SECRET) {
    throw new Error('Spotify credentials missing');
  }

  try {
    const response = await axios.post('https://accounts.spotify.com/api/token', 
      new URLSearchParams({
        'grant_type': 'client_credentials'
      }), {
      headers: {
        'Authorization': 'Basic ' + btoa(
          `${SPOTIFY_CLIENT_ID}:${SPOTIFY_CLIENT_SECRET}`
        ),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    if (!response.data.access_token) {
      throw new Error('No access token received from Spotify');
    }

    spotifyToken = response.data.access_token;
    spotifyTokenExpiry = Date.now() + (response.data.expires_in * 1000);
    return spotifyToken;
  } catch (error) {
    console.error('Error getting Spotify token:', error);
    throw new Error('Failed to get Spotify access token');
  }
}

async function searchMedia(query: string): Promise<MagicSearchResult[]> {
  try {
    const token = await getSpotifyToken();
    
    const [tracksResponse, showsResponse] = await Promise.all([
      axios.get('https://api.spotify.com/v1/search', {
        headers: { 'Authorization': `Bearer ${token}` },
        params: {
          q: query,
          type: 'track,album',
          limit: 3
        }
      }),
      axios.get('https://api.spotify.com/v1/search', {
        headers: { 'Authorization': `Bearer ${token}` },
        params: {
          q: query,
          type: 'show,episode',
          limit: 2
        }
      })
    ]);

    console.log('Spotify tracks response:', tracksResponse.data);
    console.log('Spotify shows response:', showsResponse.data);

    const tracks = tracksResponse.data.tracks.items.map((track: any) => ({
      id: track.id,
      type: 'media',
      title: track.name,
      subtitle: `Song by ${track.artists[0].name}`,
      image: track.album.images[0]?.url,
      url: track.external_urls.spotify,
      rating: track.popularity / 20,
      source: 'spotify'
    }));

    const shows = showsResponse.data.shows.items.map((show: any) => ({
      id: show.id,
      type: 'media',
      title: show.name,
      subtitle: 'Podcast',
      image: show.images[0]?.url,
      url: show.external_urls.spotify,
      rating: show.rating,
      source: 'spotify'
    }));

    return [...tracks, ...shows];
  } catch (error) {
    console.error('Spotify search error:', error);
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
      console.error('Request config:', {
        url: error.config?.url,
        headers: {
          ...error.config?.headers,
          'Authorization': 'Bearer [REDACTED]' // Hide the actual token
        },
        params: error.config?.params
      });
    }
    return [];
  }
}

// Helper function to extract location from query
function extractLocationFromQuery(query: string): { searchTerm: string, location: string | null } {
  // Clean the query first - remove question marks and normalize spaces
  const cleanQuery = query.replace(/\?/g, '').trim();
  
  // Common location patterns
  const patterns = [
    /\b(?:in|at|near|around)\s+([A-Za-z\s,]+)$/i,    // "in NYC", "in New York"
    /\b(?:in|at|near|around)\s+([A-Za-z\s,]+)/i,     // "in NYC" (anywhere in string)
    /,\s*([A-Za-z\s]+(?:,\s*[A-Za-z]{2})?)$/i,      // "Restaurant, New York, NY"
    /\b(NYC|New York City|New York|NY)\b/i,          // Common city variations
    /\b(LA|Los Angeles|San Francisco|SF|Chicago|Miami|Boston)\b/i  // Other major cities
  ];

  for (const pattern of patterns) {
    const match = cleanQuery.match(pattern);
    if (match) {
      const location = match[1].trim();
      // Remove the location part and any trailing commas or spaces
      const searchTerm = cleanQuery
        .replace(pattern, '')
        .replace(/,\s*$/, '')
        .replace(/\s+/g, ' ')
        .trim();
      
      console.log(`Location extracted: "${location}", Search term: "${searchTerm}"`);
      return { searchTerm, location };
    }
  }

  // If no location pattern is found, return the full query as the search term
  console.log('No location found in query, using full term:', cleanQuery);
  return { searchTerm: cleanQuery, location: null };
}

async function searchPlaces(term: string, location: LocationData): Promise<MagicSearchResult[]> {
  if (!FOURSQUARE_API_KEY || !term.trim()) {
    return [];
  }

  try {
    const params: any = {
      query: term,
      limit: 50,
      sort: 'RELEVANCE',
      fields: 'fsq_id,name,location,rating,photos,description,categories,distance'
    };

    if (location?.city) {
      console.log('Using location:', location.city);
      params.near = location.city;
    }
    else if (location?.coords) {
      console.log('Using coordinates');
      params.ll = `${location.coords.latitude},${location.coords.longitude}`;
      params.radius = 100000;
    }

    console.log('Making Foursquare request with params:', params);

    const response = await axios.get<FoursquareResponse>(
      FOURSQUARE_API_URL,
      {
        headers: {
          'Authorization': FOURSQUARE_API_KEY,
          'Accept': 'application/json'
        },
        params
      }
    );

    // If no results with location constraints, try a global search
    if (!response.data.results?.length) {
      console.log('No results with location, trying global search');
      const globalParams = {
        query: term,
        limit: 50,
        sort: 'RELEVANCE',
        fields: 'fsq_id,name,location,rating,photos,description,categories,distance'
      };

      const globalResponse = await axios.get<FoursquareResponse>(
        FOURSQUARE_API_URL,
        {
          headers: {
            'Authorization': FOURSQUARE_API_KEY,
            'Accept': 'application/json'
          },
          params: globalParams
        }
      );

      response.data = globalResponse.data;
    }

    const results = (response.data.results || [])
      .filter(place => place.name && place.location)
      .map(place => ({
        id: place.fsq_id,
        type: 'place' as const,
        title: place.name,
        subtitle: place.location.formatted_address || 
                  `${place.location.address || ''} ${place.location.locality || ''} ${place.location.region || ''}`.trim(),
        rating: place.rating || 0,
        reviews: 0,
        image: place.photos?.[0] ? 
          `${place.photos[0].prefix}original${place.photos[0].suffix}` : 
          undefined,
        description: place.description,
        categories: place.categories?.map(cat => cat.name),
        source: 'foursquare' as const,
        url: `https://foursquare.com/v/${place.fsq_id}`,
        distance: place.distance || 0
      }))
      .sort((a, b) => {
        // First, prioritize exact name matches
        const aExactMatch = a.title.toLowerCase() === term.toLowerCase();
        const bExactMatch = b.title.toLowerCase() === term.toLowerCase();
        if (aExactMatch && !bExactMatch) return -1;
        if (!aExactMatch && bExactMatch) return 1;

        // Then, prioritize places that start with the search term
        const aStartsWith = a.title.toLowerCase().startsWith(term.toLowerCase());
        const bStartsWith = b.title.toLowerCase().startsWith(term.toLowerCase());
        if (aStartsWith && !bStartsWith) return -1;
        if (!aStartsWith && bStartsWith) return 1;

        // Finally, sort by rating
        return (b.rating || 0) - (a.rating || 0);
      });

    return results;

  } catch (error) {
    console.error('Foursquare search error:', error);
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
    }
    // Try a global search if the location-based search fails
    try {
      const globalParams = {
        query: term,
        limit: 50,
        sort: 'RELEVANCE',
        fields: 'fsq_id,name,location,rating,photos,description,categories,distance'
      };

      const globalResponse = await axios.get<FoursquareResponse>(
        FOURSQUARE_API_URL,
        {
          headers: {
            'Authorization': FOURSQUARE_API_KEY,
            'Accept': 'application/json'
          },
          params: globalParams
        }
      );

      return (globalResponse.data.results || [])
        .filter(place => place.name && place.location)
        .map(place => ({
          id: place.fsq_id,
          type: 'place' as const,
          title: place.name,
          subtitle: place.location.formatted_address || 
                    `${place.location.address || ''} ${place.location.locality || ''} ${place.location.region || ''}`.trim(),
          rating: place.rating || 0,
          reviews: 0,
          image: place.photos?.[0] ? 
            `${place.photos[0].prefix}original${place.photos[0].suffix}` : 
            undefined,
          description: place.description,
          categories: place.categories?.map(cat => cat.name),
          source: 'foursquare' as const,
          url: `https://foursquare.com/v/${place.fsq_id}`
        }));
    } catch (globalError) {
      console.error('Global search error:', globalError);
      return [];
    }
  }
}

async function searchProducts(query: string): Promise<MagicSearchResult[]> {
  if (!SERP_API_KEY) {
    console.error('SERP API key not found');
    return [];
  }

  try {
    // Use a more reliable CORS proxy service
    const CORS_PROXY = 'https://api.allorigins.win/raw?url=';
    
    const params = new URLSearchParams({
      q: query,
      engine: 'google_shopping',
      api_key: SERP_API_KEY,
      num: '10'
    });

    const encodedUrl = encodeURIComponent(`${SERP_API_URL}?${params.toString()}`);
    const proxyUrl = `${CORS_PROXY}${encodedUrl}`;

    console.log('Making SerpAPI request with params:', { 
      ...Object.fromEntries(params.entries()), 
      api_key: '[REDACTED]' 
    });

    const response = await axios.get(proxyUrl);
    console.log('SerpAPI raw response:', response.data);

    if (!response.data?.shopping_results?.length) {
      return [];
    }

    return response.data.shopping_results
      .map((item: any) => ({
        id: `product-${item.position}`,
        type: 'thing' as const,
        title: item.title,
        subtitle: `${item.price}${item.rating ? ` · ${item.rating}★` : ''}${item.source ? ` · ${item.source}` : ''}`,
        source: 'google_shopping',
        url: item.product_link || item.link,
        image: item.thumbnail,
        price: item.price,
        rating: parseFloat(item.rating) || 0,
        reviews: parseInt(item.reviews, 10) || 0,
        merchant: item.source,
        link: item.product_link || item.link
      }));

  } catch (error) {
    console.error('SerpAPI search error:', error);
    if (axios.isAxiosError(error)) {
      console.error('Response data:', error.response?.data);
      console.error('Request config:', {
        url: error.config?.url,
        params: error.config?.params
      });
    }
    return [];
  }
}

export async function magicSearch(
  query: string, 
  location: LocationData, 
  type: 'place' | 'media' | 'general' | 'product' | { value: 'place' | 'media' | 'general' | 'product' },
  prefLinkTitle?: string
): Promise<MagicSearchResult[]> {
  console.log('magicSearch called with:', { query, location, type, prefLinkTitle });

  // Auto-infer type if it's 'general'
  let searchType = typeof type === 'object' && type !== null ? type.value : type;
  
  if (searchType === 'general') {
    // Check for media-related keywords
    const mediaKeywords = /\b(movie|banger|karaoke|film|show|series|song|album|podcast|music|artist|band)\b/i;
    if (mediaKeywords.test(query) || mediaKeywords.test(prefLinkTitle || '')) {
      searchType = 'media';
    }
    // Check for product-related keywords
    else if (/\b(buy|price|product|pair of|under|coat|pants|jeans|shirt|shoes|sneakers|store|shop|amazon|walmart)\b/i.test(query)) {
      searchType = 'product';
    }
    // Check for place-related keywords
    else if (/\b(restaurant|cafe|bar|pizza|burger|coworking|office|cozy|chill|dinner|lunch|breakfast|brunch|coffee|hotel|place|venue|location|near|around|in)\b/i.test(query)) {
      searchType = 'place';
    }
    // Default to place if location is mentioned in title
    else if (prefLinkTitle && extractLocationFromQuery(prefLinkTitle).location) {
      searchType = 'place';
    }
    // Default to product as fallback
    else {
      searchType = 'product';
    }
    console.log('Inferred search type:', searchType);
  }

  if (searchType === 'media') {
    return searchMedia(query);
  }

  if (searchType === 'product') {
    const results = await searchProducts(query);
    console.log('Product search results:', results);
    return results;
  }

  if (searchType === 'place' || searchType === 'general') {
    // Extract location from PrefLink title if available
    const { location: titleLocation } = prefLinkTitle ? 
      extractLocationFromQuery(prefLinkTitle) : 
      { location: null };

    console.log('Location from PrefLink title:', titleLocation);

    // If we found a location in the title, use it instead of the passed location
    const searchLocation = titleLocation ? 
      { city: titleLocation, region: '', country: '' } : 
      location;

    return searchPlaces(query, searchLocation);
  }

  return [];
}