// src/pages/Home.tsx
import { useNavigate } from 'react-router-dom';
import pLogo from '../assets/p-logo.svg';
import bg from '../assets/bg.png';

import { useEffect, useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from '../hooks/useLocation';

// Add this type definition at the top of the file
type RecommendationStory = {
  avatar: string;
  name: string;
  query: string;
  friendCount: number;
  timeAgo: string;
  location: string;
  photo: string;
};

// Add this helper function at the top of the file
const getNearbyLocations = (city: string, region: string) => {
  // Map of major regions and their nearby cities
  const nearbyMap: { [key: string]: string[] } = {
    'NY': ['Brooklyn', 'Queens', 'Manhattan', 'Bronx', 'Staten Island', 'Jersey City', 'Hoboken', 'Long Island'],
  'CA': ['Los Angeles', 'Santa Monica', 'Beverly Hills', 'Pasadena', 'Long Beach', 'Burbank', 'Glendale', 'Hollywood'],
  'IL': ['Chicago', 'Evanston', 'Oak Park', 'Naperville', 'Schaumburg', 'Aurora', 'Skokie', 'Wheaton'],
  'TX': ['Houston', 'Sugar Land', 'Pearland', 'Pasadena', 'The Woodlands', 'Katy', 'Baytown', 'Galveston'],
  'TX-Dallas': ['Dallas', 'Fort Worth', 'Plano', 'Irving', 'Arlington', 'Garland', 'Richardson', 'Denton'],
  'PA': ['Philadelphia', 'Camden', 'Cherry Hill', 'Wilmington', 'King of Prussia', 'Bala Cynwyd', 'Media', 'Newark'],
  'AZ': ['Phoenix', 'Scottsdale', 'Mesa', 'Chandler', 'Tempe', 'Glendale', 'Peoria', 'Gilbert'],
  'TX-SA': ['San Antonio', 'New Braunfels', 'Boerne', 'Schertz', 'Cibolo', 'Helotes', 'Universal City', 'Seguin'],
  'CA-SD': ['San Diego', 'La Jolla', 'Chula Vista', 'Coronado', 'Escondido', 'Oceanside', 'Carlsbad', 'Del Mar'],
  'CA-SF': ['San Francisco', 'Oakland', 'Berkeley', 'San Mateo', 'Daly City', 'Palo Alto', 'Redwood City', 'San Jose'],
  'NV': ['Las Vegas', 'Henderson', 'North Las Vegas', 'Boulder City', 'Paradise', 'Spring Valley', 'Enterprise', 'Summerlin'],
  'FL': ['Miami', 'Fort Lauderdale', 'Hialeah', 'Coral Gables', 'Doral', 'Miami Beach', 'Hollywood', 'Homestead'],
  'FL-TPA': ['Tampa', 'St. Petersburg', 'Clearwater', 'Brandon', 'Largo', 'Palm Harbor', 'Sarasota', 'Bradenton'],
  'GA': ['Atlanta', 'Decatur', 'Marietta', 'Sandy Springs', 'Roswell', 'Alpharetta', 'Dunwoody', 'Peachtree City'],
  'MA': ['Boston', 'Cambridge', 'Somerville', 'Brookline', 'Quincy', 'Newton', 'Waltham', 'Medford'],
  'DC': ['Washington DC', 'Arlington', 'Alexandria', 'Silver Spring', 'Bethesda', 'Hyattsville', 'Falls Church', 'Tysons'],
  'WA': ['Seattle', 'Bellevue', 'Redmond', 'Kirkland', 'Renton', 'Lynnwood', 'Everett', 'Tacoma'],
  'CO': ['Denver', 'Aurora', 'Boulder', 'Littleton', 'Englewood', 'Lakewood', 'Westminster', 'Arvada'],
  'TN': ['Nashville', 'Franklin', 'Brentwood', 'Hendersonville', 'Murfreesboro', 'Smyrna', 'Mount Juliet', 'Gallatin'],
  'OR': ['Portland', 'Beaverton', 'Gresham', 'Hillsboro', 'Lake Oswego', 'Tigard', 'Vancouver (WA)', 'Milwaukie'],
  'TX-AUS': ['Austin', 'Round Rock', 'Pflugerville', 'Cedar Park', 'Georgetown', 'San Marcos', 'Leander', 'Kyle'],
  'NC': ['Charlotte', 'Concord', 'Gastonia', 'Huntersville', 'Matthews', 'Mooresville', 'Rock Hill', 'Pineville'],
  'IN': ['Indianapolis', 'Carmel', 'Fishers', 'Greenwood', 'Plainfield', 'Zionsville', 'Noblesville', 'Avon'],
  'OH': ['Columbus', 'Dublin', 'Westerville', 'New Albany', 'Gahanna', 'Hilliard', 'Worthington', 'Reynoldsburg'],
  'KY': ['Louisville', 'Jeffersontown', 'Shively', 'St. Matthews', 'Fern Creek', 'Highlands', 'Anchorage', 'Crestwood'],
  'OK': ['Oklahoma City', 'Edmond', 'Moore', 'Norman', 'Midwest City', 'Del City', 'Yukon', 'Mustang'],
  'WI': ['Milwaukee', 'Waukesha', 'Brookfield', 'West Allis', 'Racine', 'Kenosha', 'New Berlin', 'Menomonee Falls'],
  'NM': ['Albuquerque', 'Santa Fe', 'Rio Rancho', 'Los Lunas', 'Bernalillo', 'Corrales', 'Edgewood', 'Placitas'],
  'HI': ['Honolulu', 'Waikiki', 'Kailua', 'Kaneohe', 'Ewa Beach', 'Kapolei', 'Pearl City', 'Mililani'],
  'MO': ['Kansas City', 'Overland Park', 'Olathe', 'Lee’s Summit', 'Independence', 'Blue Springs', 'Lenexa', 'Shawnee'],
  'MO-STL': ['St. Louis', 'Clayton', 'Kirkwood', 'Chesterfield', 'Florissant', 'St. Charles', 'Ballwin', 'Maryland Heights'],
  'MN': ['Minneapolis', 'St. Paul', 'Bloomington', 'Eden Prairie', 'Edina', 'Minnetonka', 'Burnsville', 'Maple Grove'],
  'AZ-TUS': ['Tucson', 'Oro Valley', 'Marana', 'Vail', 'Green Valley', 'Sahuarita', 'Catalina Foothills', 'Rita Ranch'],
  'FL-ORL': ['Orlando', 'Winter Park', 'Altamonte Springs', 'Kissimmee', 'Clermont', 'Sanford', 'Lake Mary', 'Apopka'],
  'TX-FTW': ['Fort Worth', 'Arlington', 'Grapevine', 'Southlake', 'Euless', 'Keller', 'Colleyville', 'Hurst'],
  'LA': ['New Orleans', 'Metairie', 'Kenner', 'Harvey', 'Gretna', 'Slidell', 'Westwego', 'Covington'],
  'MI': ['Detroit', 'Ann Arbor', 'Dearborn', 'Royal Oak', 'Farmington Hills', 'Southfield', 'Bloomfield Hills', 'Troy'],
  'NV-RNO': ['Reno', 'Sparks', 'Carson City', 'Incline Village', 'Fernley', 'Truckee', 'Tahoe City', 'Virginia City'],
  'UT': ['Salt Lake City', 'West Valley City', 'Murray', 'Sandy', 'Draper', 'South Jordan', 'Bountiful', 'Layton'],
  'PA-PT': ['Pittsburgh', 'Cranberry Township', 'Bethel Park', 'Monroeville', 'Sewickley', 'Wexford', 'Canonsburg', 'Upper St. Clair'],
  'SC': ['Charleston', 'Mount Pleasant', 'North Charleston', 'Summerville', 'Folly Beach', 'Isle of Palms', 'Goose Creek', 'West Ashley'],
  'FL-FTL': ['Fort Lauderdale', 'Hollywood', 'Pembroke Pines', 'Miramar', 'Weston', 'Plantation', 'Coral Springs', 'Davie']
    // Add more regions as needed
  };

  // Default to the user's city and some generic nearby areas if region not found
  return nearbyMap[region] || [
    city,
    `Downtown ${city}`,
    `North ${city}`,
    `South ${city}`,
  ];
};

export function Home() {
  const navigate = useNavigate();
  const { location, loading } = useLocation();
  const [activeStoryIndex, setActiveStoryIndex] = useState(0);

  // First, declare the recommendationStories array
  const recommendationStories: RecommendationStory[] = [
    {
        avatar: "https://plus.unsplash.com/premium_photo-1690407617542-2f210cf20d7e?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", // Sarah
        name: "Sarah K.",
        query: "Date night spots with live music",
        friendCount: 9,
        timeAgo: "1m ago",
        location: '${city}',
        photo: "https://images.unsplash.com/photo-1718044437666-723975b1a7d0?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
        avatar: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=150&q=80", // Mike
        name: "Mike R.",
        query: "Best ramen spots open late night",
        friendCount: 11,
        timeAgo: "2m ago",
        location: "Los Angeles",
        photo: "https://images.unsplash.com/photo-1623341214825-9f4f963727da?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?w=150&q=80", // Lucia
        name: "Lucia M.",
        query: "Rooftop bars with skyline views",
        friendCount: 14,
        timeAgo: "3m ago",
        location: "Chicago",
        photo: "https://images.unsplash.com/photo-1644027614757-156915491e36?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
        avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150&q=80", // David
        name: "David P.",
        query: "Gym with good day passes for travelers",
        friendCount: 7,
        timeAgo: "4m ago",
        location: "Miami",
        photo: "https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=150&q=80", // Nina
        name: "Nina C.",
        query: "Vintage clothing stores with designer finds",
        friendCount: 16,
        timeAgo: "5m ago",
        location: "Brooklyn",
        photo: "https://images.unsplash.com/photo-1489987707025-afc232f7ea0f?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=150&q=80", // James
        name: "James L.",
        query: "Best spots for weekend brunch with groups",
        friendCount: 13,
        timeAgo: "6m ago",
        location: "Seattle",
        photo: "https://images.unsplash.com/photo-1533777857889-4be7c70b33f7?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=150&q=80", // Sofia
        name: "Sofia R.",
        query: "Hidden speakeasies with craft cocktails",
        friendCount: 18,
        timeAgo: "7m ago",
        location: "San Francisco",
        photo: "https://images.unsplash.com/photo-1470337458703-46ad1756a187?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1519345182560-3f2917c472ef?w=150&q=80", // Alex
        name: "Alex T.",
        query: "Running trails with good scenery",
        friendCount: 8,
        timeAgo: "8m ago",
        location: "Denver",
        photo: "https://images.unsplash.com/photo-1498581444814-7e44d2fbe0e2?q=80&w=2898&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
        avatar: "https://images.unsplash.com/photo-1517841905240-472988babdf9?w=150&q=80", // Maya
        name: "Maya H.",
        query: "Art galleries with upcoming exhibitions",
        friendCount: 12,
        timeAgo: "9m ago",
        location: "Boston",
        photo: "https://images.unsplash.com/photo-1563293743-a9761195b52e?q=80&w=2278&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
        avatar: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=150&q=80", // Lucas
        name: "Lucas B.",
        query: "Best pizza delivery after midnight",
        friendCount: 10,
        timeAgo: "10m ago",
        location: "Houston",
        photo: "https://images.unsplash.com/photo-1513104890138-7c749659a591?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1534751516642-a1af1ef26a56?w=150&q=80", // Isabella
        name: "Isabella F.",
        query: "Bookstores with good coffee shops",
        friendCount: 15,
        timeAgo: "11m ago",
        location: "Portland",
        photo: "https://images.unsplash.com/photo-1526170375885-4d8ecf77b99f?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150&q=80", // Omar
        name: "Omar K.",
        query: "Best spots for authentic street tacos",
        friendCount: 19,
        timeAgo: "12m ago",
        location: "Phoenix",
        photo: "https://images.unsplash.com/photo-1565299585323-38d6b0865b47?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1488426862026-3ee34a7d66df?w=150&q=80", // Zoe
        name: "Zoe W.",
        query: "Yoga studios with drop-in classes",
        friendCount: 11,
        timeAgo: "13m ago",
        location: "Atlanta",
        photo: "https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?w=150&q=80", // Thomas
        name: "Thomas M.",
        query: "Record stores with rare vinyl",
        friendCount: 13,
        timeAgo: "14m ago",
        location: "Nashville",
        photo: "https://images.unsplash.com/photo-1483412033650-1015ddeb83d1?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1524504388940-b1c1722653e1?w=150&q=80", // Ana
        name: "Ana G.",
        query: "Best spots for sunset photography",
        friendCount: 17,
        timeAgo: "1m ago",
        location: "Philadelphia",
        photo: "https://images.unsplash.com/photo-1472120435266-53107fd0c44a?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150&q=80", // Lily
        name: "Lily C.",
        query: "Thrift stores with designer finds",
        friendCount: 12,
        timeAgo: "3m ago",
        location: "Las Vegas",
        photo: "https://images.unsplash.com/photo-1441986300917-64674bd600d8?ixlib=rb-4.0.3"
      },
        {
        avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=150&q=80", // Marcus
        name: "Marcus J.",
        query: "Best food trucks for lunch",
        friendCount: 16,
        timeAgo: "4m ago",
        location: "Oklahoma City",
        photo: "https://plus.unsplash.com/premium_photo-1683141398969-4ce2f7ec9c78?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
      },
      {
        avatar: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=150&q=80", // Hannah
        name: "Hannah K.",
        query: "Coworking spaces with good coffee",
        friendCount: 15,
        timeAgo: "5m ago",
        location: "Minneapolis",
        photo: "https://images.unsplash.com/photo-1497366811353-6870744d04b2?ixlib=rb-4.0.3"
      },
      {
        avatar: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=150&q=80", // Leo
        name: "Leo R.",
        query: "Best spots for live jazz",
        friendCount: 20,
        timeAgo: "6m ago",
        location: "New Orleans",
        photo: "https://images.unsplash.com/photo-1415201364774-f6f0bb35f28f?ixlib=rb-4.0.3"
      }
  ];

  // Get localized data based on user's location
  const city = location?.city || 'New York';
  
  // Then use recommendationStories in the useMemo hooks
  const localizedSuggestions = useMemo(() => [
    `What are the best jeans under $100?`, // General
    `Where’s the most laptop-friendly hotel lobby in ${city}?`, // City-specific
    `Who makes the absolute best burger in ${city}?`, // City-specific
    `What’s the coziest jazz club in ${city}?`, // City-specific
    `Where can I find the best coffee shops in ${city}?`, // City-specific
    `What are the hidden gems in ${city} that I shouldn’t miss?`, // City-specific
    `Where can I find the best sustainable fashion for under $50?`, // General
    `What’s the best weekend getaway within 3 hours of ${city}?`, // City-specific
    `Where’s the best place for vintage shopping in ${city}?`, // City-specific
    `What are the top-rated spas in ${city} for a self-care day?`, // City-specific
    `What’s the best affordable and stylish desk for working from home?`, // General
    `What’s the best spot for late-night food in ${city}?`, // City-specific
    `What’s the most unique museum or gallery in ${city}?`, // City-specific
    `Where’s the best place to catch a sunset in ${city}?`, // City-specific
    `What are the most useful tech gadgets under $50?`, // General
    `Where are the most Instagrammable spots in ${city}?`, // City-specific
    `What are the best luxury fragrances that won’t break the bank?`, // General
    `What are the top-rated hiking trails near ${city}?`, // City-specific
    `What’s the best DIY hack for organizing small spaces?`, // General
    `What’s the best bookstore in ${city} with cozy reading nooks?`, // City-specific
    `What are some quick and healthy snacks that you swear by?`, // General
    `What are the best local breweries to try in ${city}?`, // City-specific
    `What’s the best minimalist wallet that’s worth investing in?`, // General
    `Where’s the best rooftop bar in ${city}?`, // City-specific
    `What’s an essential wardrobe piece for every season?`, // General
    `Where can I find live music every night in ${city}?`, // City-specific
    `What are the top gyms in ${city} with drop-in classes?`, // City-specific
    `What are your best budget-friendly meal prep ideas?`, // General
    `Where’s the best place to learn a new hobby in ${city}?`, // City-specific
    `What are the best subscription boxes for self-care?`, // General
    `What’s the most unique date night idea in ${city}?` // City-specific
  ], [city]);

  const localizedStories = useMemo(() => {
    const nearbyLocations = getNearbyLocations(city, location?.region || '');
    
    return recommendationStories.map((story, index) => ({
      ...story,
      location: nearbyLocations[index % nearbyLocations.length],
      query: story.query.replace(/in [A-Za-z\s]+$/, `in ${nearbyLocations[index % nearbyLocations.length]}`),
    }));
  }, [city, location?.region, recommendationStories]);

  // Rotate through stories
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStoryIndex((current) => 
        current === recommendationStories.length - 1 ? 0 : current + 1
      );
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  const handleInteraction = () => {
    navigate('/new');
  };

  if (loading) {
    return <div className="min-h-screen bg-preferred-purple flex items-center justify-center">
      <div className="text-white">Loading...</div>
    </div>;
  }

  return (
    <div 
      className="min-h-screen bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-md mx-auto px-6 py-12">
        <img 
          src={pLogo} 
          alt="Pref" 
          className="w-32 mx-auto mb-8 brightness-200" 
        />
        
        <h2 className="text-sm tracking-wider text-off-white/100 font-black mb-4 text-center uppercase">
          I'm looking for...
        </h2>

        <div onClick={handleInteraction} className="w-full tracking-wider py-4 px-6 bg-off-white/100 rounded-[15px] text-preferred-purple/40 mb-10 text-center text-lg font-bold cursor-text">        
            GET RECS FROM YOUR FRIENDS
        </div>

        <div className="h-[50px] overflow-hidden relative">
          <div className="animate-carousel absolute w-full">
            {[...localizedSuggestions, ...localizedSuggestions].map((suggestion, index) => (
              <button
                key={index}
                onClick={() => navigate('/new', { state: { title: suggestion }})}
                className="w-full p-8 text-center leading-none text-off-white/40 font-black 
                         text-sm tracking-wide leading-2px hover:text-off-white/80
                         transition-colors uppercase block h-[60px]"
              >
                {suggestion}
              </button>
            ))}
          </div>
        </div>
      </div>

     
      {/* Updated recommendation cards with Framer Motion */}
      <div className=" bottom-0 left-0 right-0 p-4">
        <div className="text-center mb-4">
          <p className="text-off-white/60 p-4 leading-none text-lg uppercase font-black tracking-wide">
            Join thousands of people getting personalized recommendations in {city}
          </p>
        </div>

        <div className="max-w-md mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStoryIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ 
                duration: 0.5,
                ease: "easeOut"
              }}
            >
              {/* Keep your existing card layout exactly as is */}
              {/* <div className="relative h-32">
                <img 
                  src={localizedStories[activeStoryIndex].photo}
                  alt="Recommendation preview"
                  className="w-full h-full object-cover rounded-t-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-t-2xl" />
              </div> */}

              <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-4">
                <div className="flex items-center gap-3">
                  <img 
                    src={localizedStories[activeStoryIndex].avatar}
                    alt=""
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <div>
                    <p className="text-white font-bold">
                      {localizedStories[activeStoryIndex].name}
                    </p>
                    <div className="flex items-center gap-2 text-white/60 text-sm">
                    <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                    </svg>
                      <span>{localizedStories[activeStoryIndex].location}</span>
                      <span>•</span>
                      <span>{localizedStories[activeStoryIndex].timeAgo}</span>
                    </div>
                  </div>
                </div>
                
                <p className="text-off-white mt-3 uppercase font-black leading-none tracking-wide text-[20px]">
                  {localizedStories[activeStoryIndex].query}
                </p>
                
                <div className="flex items-center gap-2 mt-3 text-white/60">
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                  <span>
                    {localizedStories[activeStoryIndex].friendCount} friends gave recommendations
                  </span>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}