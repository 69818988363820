// src/App.tsx
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { NewPrefLink } from './pages/NewPrefLink';
import { HomePage } from './pages/HomePage';
import { SignUpPage } from './pages/SignUpPage';
import { PrefView } from './pages/PrefView';
import { SubmitRecommendation } from './pages/SubmitRecommendation';
import { AuthProvider } from './contexts/AuthContext';
import { PageTracker } from './components/PageTracker';

function App() {
  return (
    <AuthProvider>
      <Router>
        <PageTracker />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/new" element={<NewPrefLink />} />
          <Route path="/pref/:ownerToken" element={<PrefView />} />
          <Route path="/p/:publicId" element={<PrefView />} />
          <Route path="/p/:publicId/submit" element={<SubmitRecommendation />} />
          <Route path="/submit/:publicId" element={<SubmitRecommendation />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/signup" element={<SignUpPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;