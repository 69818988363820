import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyAMnA2q7YsdfsWK9xE6JCBtoTNPRsa8K-s",
    authDomain: "preferred-app.firebaseapp.com",
    projectId: "preferred-app",
    storageBucket: "preferred-app.firebasestorage.app",
    messagingSenderId: "1014736103414",
    appId: "1:1014736103414:web:44db991c523688503509d7",
    measurementId: "G-2H8SCW6WNR"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Auth
export const auth = getAuth(app);