import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../lib/firebase';
import { generatePublicId, generateOwnerToken } from '../utils/PrefLink';
import pLogo from '../assets/p-logo.svg';
import bg from '../assets/bg.png';
import { trackEvent } from '../utils/analytics';

export function NewPrefLink() {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState(location.state?.title || '');
  const [timeWindow, setTimeWindow] = useState<1 | 6 | 24>(24);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [description, setDescription] = useState('');
  const [currentPlaceholder, setCurrentPlaceholder] = useState(0);

  const placeholders = [
    "A laptop friendly hotel lobby",
    "The best pizza in Brooklyn",
    "A quiet coffee shop to work from",
    "Dog friendly restaurants"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlaceholder(current => 
        current === placeholders.length - 1 ? 0 : current + 1
      );
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSubmitting(true);
    
    try {
      const publicId = generatePublicId();
      const ownerToken = generateOwnerToken();
      console.log('Creating PrefLink with publicId:', publicId);

      // Updated data structure with proper timestamps
      const prefData = {
        publicId,
        ownerToken,
        title,
        description,
        createdAt: serverTimestamp(),
        expiresAt: new Date(Date.now() + timeWindow * 60 * 60 * 1000), // Convert hours to milliseconds
        isLocked: false,
        unlockPrice: 0,
        recommendationCount: 0,
        viewCount: 0,
        owner: auth.currentUser?.uid || 'anonymous',
        unlockedBy: []
      };

      await addDoc(collection(db, 'prefs'), prefData);
      navigate(`/pref/${ownerToken}`);
      trackEvent('pref_created', {
        title,
        timeWindow
      });
    } catch (error) {
      console.error('Error creating PrefLink:', error);
      setError('Failed to create your Pref. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div 
      className="min-h-screen bg-preferred-purple font-['Outfit'] bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="max-w-md mx-auto px-6 py-12">
        <img 
          src={pLogo} 
          alt="Pref" 
          className="w-32 mx-auto mb-16 brightness-200" 
        />
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
          {error && (
            <div className="text-red-400 bg-preferred-pink-400/10 p-4 rounded-lg text-center">
              {error}
            </div>
          )}

          <div className="text-center">
            <label className="block text-off-white font-extrabold mb-6 text-2xl tracking-wider text-center">
              I'M LOOKING FOR...
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={placeholders[currentPlaceholder]}
              className="w-full p-4 bg-off-white/10 rounded-lg text-off-white 
                       placeholder:text-off-white/40 focus:outline-none text-center text-xl"
              required
              autoFocus
            />
          </div>

          <div className="text-center">
            <label className="block text-off-white font-bold mb-2 tracking-wider text-center">
              DESCRIPTION (OPTIONAL)
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Add more details about what you're looking for..."
              className="w-full p-4 bg-off-white/10 rounded-lg text-off-white 
                       placeholder:text-off-white/40 focus:outline-none
                       min-h-[100px] resize-y"
            />
          </div>

          <div className="text-center">
            <label className="block text-off-white font-bold mb-2 tracking-wider text-center">
              SUBMISSION WINDOW
            </label>
            <div className="flex gap-2 ">
              {[
                { value: 1, label: '1 HOUR' },
                { value: 6, label: '6 HOURS' },
                { value: 24, label: '24 HOURS' }
              ].map(option => (
                <button
                  key={option.value}
                  type="button"
                  onClick={() => setTimeWindow(option.value as 1 | 6 | 24)}
                  className={`flex-1 p-3 rounded-lg font-bold ${
                    timeWindow === option.value
                      ? 'bg-preferred-purple/20 text-off-white'
                      : 'bg-preferred-pink/10 text-off-white/60'
                  }`}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          <button
            type="submit"
            disabled={submitting || !title}
            className="w-full p-4 bg-preferred-purple bg-opacity-80 rounded-lg text-off-white font-bold
                     disabled:opacity-50 hover:bg-opacity-100 transition-all
                     fixed bottom-6 left-1/2 -translate-x-1/2 max-w-[calc(100%-3rem)]"
          >
            {submitting ? 'CREATING...' : 'SUBMIT'}
          </button>
        </form>
      </div>
    </div>
  );
}