import { createContext, useContext, ReactNode, useState, useEffect, useCallback } from 'react';
import { User, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../lib/firebase';

interface AuthContextType {
  user: User | null;
  signInWithPhone: (phoneNumber: string) => Promise<string>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser: User | null) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const signInWithPhone = useCallback(async (phoneNumber: string) => {
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'invisible',
          callback: () => {
            // Optional callback when reCAPTCHA is solved
          },
          'expired-callback': () => {
            // Optional callback when reCAPTCHA expires
          }
        });
      }

      await window.recaptchaVerifier.render();

      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(
        phoneNumber,
        window.recaptchaVerifier
      );

      return verificationId;
    } catch (error) {
      console.error('Error in signInWithPhone:', error);
      throw error;
    }
  }, []);

  const value = {
    user,
    signInWithPhone
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
      <div id="recaptcha-container"></div>
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}