// src/utils/PrefLink.ts
export function generatePublicId(): string {
    // Keep existing implementation
    const characters = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';
    let result = '';
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  
  export function generateOwnerToken(): string {
    return crypto.randomUUID();
  }
  
  export function getShareableLink(publicId: string, type: 'view' | 'submit' = 'view'): string {
    const baseUrl = window.location.origin;
    if (type === 'submit') {
      return `${baseUrl}/p/${publicId}/submit`;
    }
    return `${baseUrl}/p/${publicId}`;
  }
  
  export interface PrefLink {
    id: string;           // Firestore doc ID
    publicId: string;     // 4-character public ID
    ownerToken: string;   // UUID for owner access
    title: string;
    description?: string;
    createdAt: Date;
    expiresAt: Date;
    isLocked: boolean;
    unlockPrice: number;
    recommendationCount: number;
    viewCount: number;
    owner: string;
    unlockedBy: string[];
  }
  
  export interface Recommendation {
    id: string;
    PrefLinkId: string;
    text: string;
    link?: string;
    metadata?: {
      title?: string;
      description?: string;
      image?: string;
      siteName?: string;
      favicon?: string;
    };
    votes: number;
    userId: string;
    createdAt: Date;
  }
  
  // Helper function to check if a token is an owner token (UUID format)
  export function isOwnerToken(token: string): boolean {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(token);
  }
  
  // Helper function to check if an ID is a public ID (4-char format)
  export function isPublicId(id: string): boolean {
    return /^[23456789ABCDEFGHJKLMNPQRSTUVWXYZ]{4}$/.test(id);
  }