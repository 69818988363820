// Types for analytics events
type EventNames = 
  | 'pref_created'
  | 'recommendation_added'
  | 'pref_shared'
  | 'search_performed'
  | 'result_selected'
  | 'phone_verification_started'
  | 'phone_verification_completed';

// Type for event parameters
interface EventParams {
  pref_created: {
    title: string;
    timeWindow: number;
  };
  recommendation_added: {
    prefId: string;
    hasLink: boolean;
    type?: 'place' | 'thing' | 'media' | 'general';
  };
  pref_shared: {
    prefId: string;
    method: 'copy' | 'direct';
  };
  search_performed: {
    query: string;
    type: string;
    resultCount: number;
  };
  result_selected: {
    query: string;
    type: string;
    resultTitle: string;
  };
  phone_verification_started: {
    success: boolean;
  };
  phone_verification_completed: {
    success: boolean;
  };
}

// Track page views
export const trackPageView = (path: string, title?: string) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: path,
      page_title: title || document.title,
      page_location: window.location.href
    });
  }
};

// Track specific events with type safety
export const trackEvent = <T extends EventNames>(
  eventName: T,
  params: EventParams[T]
) => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, params);
  }
};