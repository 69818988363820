// src/utils/urlMetadata.ts
export interface URLMetadata {
  title?: string;
  description?: string;
  image?: string;
  siteName?: string;
  favicon?: string;
  type?: string;
  author?: string;
  price?: string;
}

export function sanitizeMetadata(metadata: any): URLMetadata {
  return {
    title: metadata.title || null,
    description: metadata.description || null,
    image: metadata.image || null,
    siteName: metadata.siteName || null,
    favicon: metadata.favicon || null,
    type: metadata.type || null,
    author: metadata.author || null,
    price: metadata.price || null,
  };
}